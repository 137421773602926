<template>
  <div
    v-show="settings"
    ref="main"
    class="main"
  >
    <!-- v-if="settings && settings['spende.app']" -->
    <div class="logo-layer">
      <div class="top-row">
        <div class="top-left" />
        <div class="top-right">
          <img
            v-if="cornerLogoUrl"
            id="corner-logo-img"
            :src="cornerLogoUrl"
          >
          <HandSquare
            v-else
            id="home-logo"
            size="100%"
          />
        </div>
      </div>
      <div class="bottom-row">
        <div class="bottom-left" />
        <div class="bottom-right" />
      </div>
    </div>
    <div
      v-show="!showDetailDescriptionDialog && !showPaymentDialog"
      id="overlay"
    >
      <div @click="openAboutDialog">
        <QuestionMark id="header-question-mark" />
      </div>
      <div id="modal-container">
        <div id="collection-selection-header">
          <div id="dialog-header" class="d-flex align-center">
            <img
              v-if="mainLogoUrl"
              :class="{'organization-logo': true, 'main-logo-img': !mainLogoIsText, 'main-logo-text': mainLogoIsText}"
              :alt="mainLogoIsText ? organizationName : null"
              :src="mainLogoUrl"
            >
            <HandSquare
              v-else
              class="organization-logo"
              size="5em"
            />
            <div
              v-if="!mainLogoIsText"
              id="parish-name"
              class="px-3"
            >
              <h3>{{ organizationName }}</h3>
            </div>
          </div>
          <div class="slider-row control-row mt-8">
            <div
              id="value-slider"
              class="slider"
            >
              <vue-slider
                v-model="value"
                height="1.2em"
                :tooltip="'always'"
                :min="1"
                :max="Math.max(300, value)"
              >
                <template #tooltip="slotProps">
                  <div
                    id="tooltip"
                  >
                    <div id="tooltip-label">
                      {{ formatAmount(slotProps.value, { fractionCount: 0 }) }}
                    </div>
<!--                    <div id="tooltip-bottom" />-->
                  </div>
                </template>
              </vue-slider>
            </div>
            <div
              :class="{'moreLabel': true, 'hideMoreLabel': value < 0}"
            >
              <span
                class="clickable"
                @click="typeInValue"
              >Eigener Betrag</span>
            </div>
          </div>
          <div
            id="explanation-text"
            class="mb-1">
            Wählen Sie den Zweck, für den Sie geben möchten
          </div>
        </div>
        <div id="list-container">
          <div class="list-outline">
            <div
              id="left-selector"
              :class="{ hiddenElement: filteredCollections.length <= 1 }"
              @click="selectNextCollection(true)"
            />
            <div class="collection-list-box">
              <div
                v-for="[collection, index] in filteredCollections"
                :key="collection.id"
                :class="{'collectionListItem': true, active: index === collectionIndex, 'clickable': true}"
                @click="selectCollection($event, index)"
              >
                <div class="handout">
                  <HandOutline
                    v-if="collection.plan.type !== null && collection.plan.type === 'donation'"
                    class="collection-hand-logo"
                    size="5em"
                    view-box="0 0 30 30"
                    height="15px"
                    width="15px"
                  />
                  <KollekteOutline
                    v-else
                    class="collection-hand-logo"
                    view-box="0 0 30 30"
                    height="15px"
                    width="15px"
                  />
                </div>
                <div class="collection-title">
                  <div style="min-width: calc(100% - 2em)">
                    {{ extractDisplayPurpose(collection.plan, 'bart') }}
                    <template v-if="collection.plan.date">
                      <br>
                      <span style="font-style: italic; font-size: 0.85em;">{{
                        collection.plan.date.name
                      }}, {{ formatDate(collection.plan.date.day, 'D. MMM YYYY') }}</span>
                    </template>
                  </div>
                  <div
                    v-if="collection.plan.meta ? collection.plan.meta.description ? !!collection.plan.meta.description.text : false : false"
                    class="info-icon"
                    @click="openDetailDescription(collection)"
                  >
                    <span style="font-family: Times New Roman; font-weight: bold; font-size: 1.1em; line-height: 1.4em">i</span>
                  </div>
                </div>
              </div>
              <div class="donation-box d-flex justify-center flex-column" v-if="$router.currentRoute.hash && hasDonationMeter">
                <div class="donation-progress">
                  <img :src="require('@/assets/ui/church-empty.svg')">
                  <div class="progress-linear">
                    <v-progress-linear
                  v-model="donationProgressLevel"
                  color="primary"
                  height="1000"
                ></v-progress-linear>
                  </div>
                </div>
                <div class="donation-text py-1 px-1 bg-current" v-if="currentDonation != null">
                  Aktueller Spendenstand: <b>{{ formatAmount(donationProgressLevel * 100 , { fractionCount: 0 })  }}</b> von <b>{{ formatAmount(goalAmount  , { fractionCount: 0 })  }}</b>
                </div>
              </div>
            </div>
            <div
              id="right-selector"
              :class="{ hiddenElement: filteredCollections.length <= 1 }"
              @click="selectNextCollection(false)"
            />
          </div>
        </div>
        <div
          id="dot-selector"
          :class="{ hiddenElement: filteredCollections.length <= 1 }"
        >
          <div
            v-for="[collection, index] in filteredCollections"
            :key="collection.id"
            :class="{'selection-dot' : true, active: index === collectionIndex, 'clickable': true}"
            @click="selectCollection($event, index)"
          />
        </div>
        <div style="width: 100%; padding-left: 0em">
          <div
            id="give-footer"
          >
            <div @click="openAboutDialog">
              <QuestionMark id="question-mark" />
            </div>
            <v-checkbox
              v-if="allowRecurring"
              v-model="doRecurring"
              class="mx-4"
              label="regelmäßig spenden"/>
            <v-btn
              color="primary"
              :disabled="filteredCollections.length === 0"
              @click="paymentFormMode = 'pay'"
            >
              <template v-if="doRecurring">
                einrichten
              </template><template v-else>
                SPENDEN!
              </template>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showCustomInput"
      id="custom-value"
    >
      <label for="custom-input-value">Eigenen Betrag eingeben:</label>
      <input
        id="custom-input-value"
        ref="customInput"
        type="number"
        :value="value"
        min="1"
        :max="inputMax"
        name="typeValue"
        @keyup.enter="confirmCustomInput"
      >
      <a
        class="custom-input-confirm"
        @click="confirmCustomInput"
      >OK</a>
    </div>
    <app-dialog
      ref="paymentDialog"
      :is-open="showPaymentDialog"
      max-width="500px"
      persistent
      @dialog-closed="paymentDialogClosed"
    >
      <payment-form
        app-kind="spende.app"
        :input="paymentInput"
        :mode="paymentFormMode"
      />
    </app-dialog>
    <app-dialog
      ref="aboutDialog"
      :is-open="showAboutDialog"
      @dialog-closed="showAboutDialog = false"
    >
      <AboutDialog />
    </app-dialog>
    <app-dialog
      ref="collectionexpired"
      :is-open="showCollectionExpiredDialog"
      :show-close-button="false"
    >
      <p>
        Der direkt verlinkte Spendenzweck ist leider nicht aktiv. Sie können gerne für eine andere Sammlung
        geben.
      </p>
      <v-btn
        @click="showCollectionExpiredDialog = false"
      >
        OK
      </v-btn>
    </app-dialog>
    <app-dialog
      ref="detailDescription"
      :is-open="showDetailDescriptionDialog"
      @dialog-closed="showDetailDescriptionDialog = false"
    >
      <div
        v-if="detailCollection && detailCollection.plan && detailCollection.plan.meta.description.text"
        style="text-align: left"
      >
        <h4 style="display: flex">
          <HandOutline
            v-if="detailCollection.plan.type !== null && detailCollection.plan.type === 'donation'"
            class="collection-hand-logo mr-2"
            size="5em"
            view-box="0 0 30 30"
            height="15px"
            width="15px"
          />
          <KollekteOutline
            v-else
            class="collection-hand-logo mr-2"
            view-box="0 0 30 30"
            height="15px"
            width="15px"
          />
          {{
            extractDisplayPurpose(detailCollection.plan, 'bart')
          }}
        </h4>
        <p
          v-if="detailCollection.plan.start"
          style="font-size: small"
        >
          <span v-if="currentCollection">
            <template v-if="currentCollection.plan.type === 'donation'">Spendenzweck</template>
            <template v-else>Kollektenzweck</template>
          </span> vom {{ momentize(detailCollection.plan.start) }}
          {{ detailCollection.plan.end ? 'bis zum ' + momentize(detailCollection.plan.end) : 'ohne Zeitbegrenzung' }}
        </p>
        <div
          class="list-outline collection-description pa-3 mb-2"
          style="border:1px solid #dddddd"
        >
          <p>
            {{ detailCollection.plan.meta.description.text }}
          </p>
          <p
            v-if="collectionWebsite"
            class="mb-0"
          >
            <v-icon
              class="mr-2"
              color="grey"
              small
            >
              {{ mdiWeb }}
            </v-icon>
            <a
              class="collection-link"
              :href="collectionWebsite"
              target="_blank"
            >{{ collectionWebsite }}</a>
          </p>
        </div>
        <p style="font-size: small; text-align: center">
          {{ currentOrganization.name }}
        </p>
      </div>
    </app-dialog>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import '@/styles/slider.styl'
import moment from 'moment'
import HandOutline from '@/components/HandOutline'
import KollekteOutline from '@/components/KollekteOutline'
import HandSquare from '@/components/HandSquare'
import { setColor } from '@/lib/colorTools'
import QuestionMark from '@/components/QuestionMark'
import AboutDialog from '@/components/AboutDialog'
import AppDialog from '../jscommon/src/components/AppDialog'
import PaymentForm from '@/jscommon/src/components/PaymentForm'
import { transformGermanAkkusativ, extractDisplayPurpose } from '@/jscommon/src/lib/regex-tools'
import { mapGetters, mapState } from 'vuex'
import { SET_COLLECTION_INDEX, SET_ORGANIZATION_ID } from '@/store/action-types'
import { mdiClose, mdiWeb } from '@mdi/js'
import { pick } from 'lodash'
import currencyMixin from "@/jscommon/src/mixins/currency.mixin"

export default {
  name: 'Collection',
  mixins: [currencyMixin],
  components: {
    VueSlider,
    KollekteOutline,
    HandOutline,
    HandSquare,
    QuestionMark,
    AboutDialog,
    PaymentForm,
    AppDialog
  },
  metaInfo () {
    const organization = this.currentOrganization
    return {
      title: organization?.name ? this.$options.filters.shortenParish(organization.name) : undefined
    }
  },
  filters: {
    transformGermanAkkusativ
  },
  data () {
    return {
      value: 5,
      customValue: 200,
      doRecurring: false,
      showCustomInput: false,
      detailCollection: null,
      showAboutDialog: false,
      showCollectionExpiredDialog: false,
      showDetailDescriptionDialog: false,
      paymentFormMode: null,
      mdiClose,
      mdiWeb,
      extractDisplayPurpose,
      donationProgressLevel: 0,
      donationGoal: 152000,
      currentDonation: null,
      goalAmount: null,
    }
  },
  computed: {
    ...mapState('display', [
      'organizationId',
      'collectionIndex'
    ]),
    ...mapGetters('display', [
      'ready',
      'collections',
      'organizationName',
      'currentCollection',
      'currentOrganization'
    ]),
    ...mapGetters('data', [
      'organizationSettingsByUrl',
      'imageBackgroundUrlByHash'
    ]),
    settings () {
      return (this.organizationSettingsByUrl[this.currentOrganization?.url] ?? {}) ?? {}
    },
    spendeAppSettings () {
      return this.settings?.WS ?? {}
    },
    inputInitial () {
      return this.spendeAppSettings?.input?.initial ?? 5
    },
    inputMax () {
      return this.spendeAppSettings?.input?.max ?? 999
    },
    primaryColor () {
      if (this.$store.state.overrideSettings.primaryColor) {
        return this.$store.state.overrideSettings.primaryColor
      }
      return this.spendeAppSettings?.primary_color ?? null
    },
    backgroundImageUrl () {
      if (this.$store.state.overrideSettings.backgroundImageUrl) {
        return this.$store.state.overrideSettings.backgroundImageUrl
      }
      return this.imageBackgroundUrlByHash[this.settings?.assets?.WS?.background?.image ?? 'default'] ?? null
    },
    logoUrl () {
      return this.imageBackgroundUrlByHash[this.settings?.assets?.WS?.logo?.image ?? null] ?? null
    },
    mainLogoUrl () {
      return this.imageBackgroundUrlByHash[this.settings?.assets?.WS?.main?.image ?? null] ?? this.logoUrl
    },
    cornerLogoUrl () {
      return this.imageBackgroundUrlByHash[this.settings?.assets?.WS?.corner?.image ?? null] ?? this.logoUrl
    },
    mainLogoIsText () {
      return this.spendeAppSettings?.main_logo_is_text ?? false
    },
    allowRecurring () {
      if (!this.settings?.features?.allow_recurring_payments ?? false) {
        return false
      }
      // FIXME Mind. 2 zusätzliche Zahlungen
      return this.currentCollection?.plan?.type === 'donation' && (this.currentCollection?.plan?.allow_recurring ?? false)
    },
    filteredCollections () {
      // Returns the filtered list of selectable collections, as array[[collection, index], ...]
      if (!this.collections) {
        return []
      }
      return this.collections.map((collection, index) => [collection, index]).filter(
        ([collection, _index]) => {
          return (this.collectionHash === null ? true : collection?.plan?.id === this.collectionHash)
        }
      )
    },
    collectionHash () {
      /* Returns the collection id in the hash if and only if collections have been loaded */
      if (!this.collections) {
        return null
      }
      // Also require collectionexpired to be mounted
      if (!this.$refs.collectionexpired) {
        return null
      }
      const hash = this.$router.currentRoute?.hash ?? ''
      if (hash.startsWith('#c/')) {
        return hash.slice(3)
      }
      return null
    },
    showPaymentDialog () {
      return this.paymentFormMode !== null
    },
    paymentInput () {
      if (!this.filteredCollections && !this.collections?.length) {
        return {}
      }
      return {
        organization: this.currentOrganization,
        collection: this.currentCollection,
        amount: this.value,
        settings: pick(this.organizationSettingsByUrl[this.currentOrganization.url] ?? {}, ['merchantMemo', 'privacyLink', 'features']),
        paymentInformation: this.$store.state.data.paymentInformationForUrl[this.currentOrganization.url] ?? [],
        doRecurring: this.allowRecurring && this.doRecurring
      }
    },
    collectionWebsite () {
      const website = this.detailCollection.meta?.external_fields?.www || null

      if (!website) {
        return null
      }

      if (website.startsWith('http')) {
        return website
      }

      return `https://${website}`
    },
    hasDonationMeter () {
      const secretToken = this.currentCollection?.plan?.meta?.secret_token
      if (!this.currentCollection) {
        return false
      }
      return this.goalAmount && secretToken
    }
  },
  watch: {
    primaryColor: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          setColor(newVal)
          this.$vuetify.theme.themes.light.primary = JSON.parse(JSON.stringify(newVal))
        }
      }
    },
    backgroundImageUrl: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.main.style.setProperty('--spendeapp-background-image', `url(${newVal})`)
          })
        }
      }
    },
    inputInitial: {
      immediate: true,
      handler (newVal) {
        this.value = newVal
      }
    },
    async $route (to, from) {
      await this._intInitialActions()
    },
    collectionHash: {
      immediate: true,
      handler: function (newVal) {
        if (newVal !== null) {
          if (this.filteredCollections.length > 0) {
            const collectionIndex = this.filteredCollections[0][1]
            this.$store.dispatch('display/' + SET_COLLECTION_INDEX, collectionIndex)
            return
          }
          this.showCollectionExpiredDialog = true
          this.$router.replace({ hash: '' })
        }
      }
    },
    currentCollection: {
      immediate: true,
      once: true,
      handler: async function (newVal) {
        if (newVal) {
          const planId = newVal.plan?.id
          const token = newVal.plan?.meta?.secret_token
          if (planId && token) {
            try {
              const resp = await this.$store.dispatch('data/fetchCollectionStats', { planId, token })
              if (resp.total) {
                this.currentDonation = Number(resp.total)
                this.goalAmount = Number(newVal.plan.meta.goal_amount) || 10000
                this.animateDonationLevel()
              }
            } catch (error) {
              throw new Error('Unable to fetch collection stats. Please try again later.')
            }
          }
        }
      }
    }
  },
  async mounted () {
    const tasks = []
    if (!this.$store.state.data.organizations.length) {
      tasks.push(this.$store.dispatch('data/fetchOrganizations'))
    }
    if (this.$route.params) {
      if (this.$store.state.display.organizationId !== this.$route.params.organizationId) {
        tasks.push(this.$store.dispatch('display/' + SET_ORGANIZATION_ID, this.$route.params.organizationId))
      }
    }

    const sliderElement = document.getElementById('value-slider')
    if (sliderElement) {
      sliderElement.addEventListener('wheel', this.handleScroll, false)
    }

    tasks.push(this._intInitialActions())

    await Promise.allSettled(tasks)
  },
  methods: {
    _intInitialActions: async function () {
      if (this.$route.name === 'done') {
        this.paymentFormMode = 'check'
      }
    },
    formatDate: function (date, format) {
      moment.locale('de')
      return date === null || format === null
        ? null
        : moment(date).format(format)
    },
    selectCustomValue: function (event) {
      if (this.value !== 200) {
        this.customValue = this.value
      }
      this.value = 200
      this.$nextTick(() => {
        this.$refs.customAmount.select()
        this.$refs.customAmount.focus()
      })
    },
    handleScroll: function (event) {
      event.preventDefault()
      const newVal = Math.round(this.value + event.deltaY / 8)
      this.value = Math.max(1, Math.min(300, newVal))
    },
    elementIsHidden: function (elem) {
      if (!elem) {
        /* FIXME Und what circumstances is is #home-logo not existing? what should be the return value then? */
        return false
      }
      const style = window.getComputedStyle(elem)
      return (style.display === 'none')
    },
    selectCollection: function (event, index) {
      this.$store.dispatch('display/' + SET_COLLECTION_INDEX, index)
    },
    typeInValue: function () {
      this.showCustomInput = true
      this.$nextTick(() => {
        this.$refs.customInput.focus()
      })
    },
    confirmCustomInput: function () {
      const newCustomVal = document.getElementById('custom-input-value').value
      this.value = Math.round(Math.min(Math.max(newCustomVal, 1), this.inputMax))
      this.showCustomInput = false
    },
    abortPayment: async function () {
      this.paymentFormMode = null
      await this.$nextTick()
      await this.goHome()
    },
    paymentDialogClosed: async function () {
      await this.abortPayment()
    },
    selectNextCollection: function (prev) {
      if (!prev) {
        if (this.collectionIndex + 1 < this.collections.length) {
          this.$store.dispatch('display/' + SET_COLLECTION_INDEX, this.collectionIndex + 1)
        } else {
          this.$store.dispatch('display/' + SET_COLLECTION_INDEX, 0)
        }
      } else {
        if (this.collectionIndex > 0) {
          this.$store.dispatch('display/' + SET_COLLECTION_INDEX, this.collectionIndex - 1)
        } else {
          this.$store.dispatch('display/' + SET_COLLECTION_INDEX, this.collections.length - 1)
        }
      }
    },
    openAboutDialog: function () {
      this.showAboutDialog = true
    },
    goHome: async function () {
      if (this.$route.name !== 'organization-selected') {
        await this.$router.replace({
          name: 'organization-selected',
          params: { organizationId: this.$store.state.display.organizationId }
        })
      }
    },
    openDetailDescription (col) {
      this.detailCollection = col
      this.showDetailDescriptionDialog = true
    },
    closeDetailDescription () {
      this.showDetailDescriptionDialog = false
    },
    momentize (momDate) {
      return moment(momDate).locale('de').format('L')
    },
    animateDonationLevel () {
      const targetValue = this.currentDonation / this.goalAmount * 100
      const duration = 2500
      const startTime = performance.now()

      const animate = (currentTime) => {
        const elapsed = currentTime - startTime
        const progress = Math.min(elapsed / duration, 1)
        this.donationProgressLevel = targetValue * progress

        if (progress < 1) {
          requestAnimationFrame(animate)
        } else {
          this.donationProgressLevel = targetValue
        }
      }
      requestAnimationFrame(animate)
    },

  }
}
</script>

<style scoped lang="stylus">
@import '../styles/main'

#value-slider
  transform: translate3d(0,0,0)

.collection-description
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)

.collection-link
  text-decoration: none

.main:after
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: white
  background-image: var(--spendeapp-background-image)
  background-position: center center
  background-repeat: no-repeat
  width: 100vw;
  height: 100vh;
  opacity: 0.7;
  z-index: -1;
  background-size: cover

.main
  width: 100vw
  height: 100vh /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
  overflow: hidden
  justify-content: center
  display: flex
  flex-direction: row

  .clickable
    cursor: pointer

  .close-dialog-button
    position: absolute
    top: 0.5em
    right: 0.5em
    text-align: center

    .close-x
      display: none

    .close-x-hover
      display: inline-block

  .close-dialog-button:hover
    background: var(--spendeapp-primary-color)

    .close-x
      display: inline-block

    .close-x-hover
      display: none

  .logo-layer
    width: 100vw
    height: 100vh /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100) /* Set in App.vue */
    max-width: 50em
    max-height: 50em
    position: fixed
    margin-top: 3em
    display: flex
    flex-direction: column
    justify-content: space-between
    pointer-events: none

    .top-row, .bottom-row
      display: flex
      flex-direction: row
      justify-content: space-between

    #home-logo
      width: 4.5em
      height: 4.5em

    #corner-logo-img
      max-width: 12em
      max-height: 4.5em
      padding: 0.5em

  .main-logo-img
    max-width: 5em
    max-height: 5em

  .main-logo-text
    max-height: 100%

  #overlay
    display: flex
    flex-direction: column
    margin-top: 7.5em
    margin-bottom: 7.5em
    margin-left: auto
    margin-right: auto
    width: 100%
    height: calc(100% - 9.5em)
    max-width: 500px

    #header-question-mark
      display: none

    .hiddenElement
      visibility: hidden

  #modal-container
    background: rgba(255, 255, 255, 0.8)
    margin-bottom: auto
    margin-top: auto
    padding: 3em 4em 1em 4em
    overflow-y: hidden
    display: flex
    flex-direction: column

  .list-outline
    position: relative
    display: flex
    padding: 1em
    background: rgba(255, 255, 255, 0.8)

    #left-selector
      display: none

    #right-selector
      display: none

  .organization-logo
    pointer-events: auto
    float: left

  #parish-name
    display: block

  .close-button2
    float: left
    width: 4em
    text-align: right
    align: right

  #custom-value
    position: absolute
    background: var(--spendeapp-primary-color)
    border: 0
    border-radius: 12px
    top: calc(40% - 3em)
    transition: top 1s ease-in-out
    padding: 1em
    z-index: 9
    font-weight: 500
    box-shadow: 0.3em 0.3em 1em .5em rgba(0, 0, 0, 0.5)

    input
      border: 0
      padding: 1em
      margin-right: 1em
      border-radius: 4px
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)

    label
      color: white
      display: block
      margin-bottom: 0.5em

    a.custom-input-confirm
      color: white
      cursor: pointer

  #custom-input-value
    background-color: white

  .info-icon
    margin-right: 0
    margin-left: auto
    margin-top: auto
    margin-bottom: auto
    text-align: center
    color: white
    line-height: 1.5em
    height: 1.6em
    min-width: 1.6em
    border-radius: 0.8em
    background: var(--spendeapp-primary-color)
    cursor: pointer

  #tooltip
    position: absolute
    left: 0em
    bottom: -2.75em
    left: -3.25em
    cursor: pointer

    #tooltip-label
      width: 72px
      text-align: center
      background: var(--spendeapp-primary-color)
      color: white
      padding: 0.4em
      font-size: large
      font-weight: 500
      min-width: 2.8em
      border-radius: 12px
      box-shadow: 0.3em 0.3em 1em .3em rgba(0, 0, 0, 0.5)

    #tooltip-bottom
      background: var(--spendeapp-primary-color)
      height: 1em
      width: 0.4em
      margin-left: auto
      margin-right: auto

  .moreLabel
    text-align: right
    color: var(--spendeapp-primary-color)
    opacity: 1
    padding-top: 1em
    transition: opacity 1s ease-in-out 0s, visibility 1s ease-out

  .hideMoreLabel
    visibility: hidden
    opacity: 0

  .collectionListItem
    display: flex
    align-items: center
    border: 2px solid rgba(0, 0, 0, 0)
    border-bottom: 1px solid rgba(0, 0, 0, 0.1)
    padding: calc(1em - 2px)
    height: auto
    vertical-align: middle

  .collectionListItem.active
    border: 2px solid var(--spendeapp-primary-color)
    padding-bottom: calc(1em - 3px)

  .collection-title
    line-height: 1.7em
    text-align: left
    font-size: small
    cursor: pointer
    width: 100%
    display: flex

  .handout
    height: 100%
    width: 2.8em
    padding-right: 1em

  .collection-hand-logo
    display: block
    vertical-align: middle
    width: 2.4em
    height: 1.7em

  .collection-list-box
    position: relative
    display: block
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3)
    text-align: center
    width: 100%

  #collection-selection-header
    text-align: left
    align: left
    font-size: small
    color: rgba(0, 0, 0, 0.54)

  #list-container
    min-height: 6em
    overflow-x: hidden
    overflow-y: auto
    -webkit-overflow-scrolling: touch

  #dot-selector
    height: 1em

  #question-mark
    padding-top: 0.5em
    width: 2.25em
    height: 2.25em
    cursor: pointer

  #about-button
    cursor: pointer

  #dialog-header
    display: block
    height: 80px

  #give-footer
    display: flex
    align-items: center
    justify-content: space-between

  .detail-collection-title
    width: calc(100% - 6em)
    text-align: center

// @media only screen and (max-width: 300px)
//   .top-right
//     display: none

// @media only screen and (min-height: 27em)
//   #dialog-header
//     display: block !important

// Mobile Style

@media screen and (max-width: 650px), screen and (max-height: 37em)
  .main:after
    opacity: 0.2

  .logo-layer
    margin-top: 0em !important

  .top-logo
    visibility: hidden

  #home-logo
    float: left
    display: none

  .main #custom-value
    top: 10em

  #overlay
    margin-top: 2em !important
    height: calc(100% - 2em) !important

    .list-outline
      padding: 0em
      padding-top: 1em
      background: rgba(255, 255, 255, 0)
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)

    .collection-list-box
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0)
      width: 100%

    .collectionListItem
      padding: 1em
      width: 100%
      margin-bottom: 1em
      background: rgba(255, 255, 255, 0.8)
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2)
      border: 2px solid rgba(0, 0, 0, 0)

    .collectionListItem.active
      border: 2px solid var(--spendeapp-primary-color)
      padding-right: calc(1em - 4px)

    #modal-container
      margin-top: 0em
      padding: 3em
      padding-bottom: 0em
      background: rgba(0, 0, 0, 0)

  #explanation-text
    visibility: hidden
    margin: 0
    padding: 0
    height: 1.5em

// small widget style
@media only screen and (max-height: 592px)
  #overlay #dialog-header, .organization-logo, .main #parish-name
    display: none
    height: 0

// large widget style
@media only screen and (max-height: 450px)
  .main:after
    opacity: 0.3

  .top-logo
    visibility: visible

  #home-logo
    display: none

  /*#header-question-mark
  display: none*/
  /* activating this css would display the question mark dialog button in the top right corner in the widget */
  /*position: absolute
  display: block !important
  width: 2.5em
  height: 2.5em
  padding: 0.5em
  cursor: pointer
  right: 0em
  top: 0em*/
  #overlay
    margin-top: 3.5em !important
    margin-left: 0em
    margin-right: 0em
    height: calc(100% - 2em)

    #modal-container
      padding: 2em
      padding-top: 0em
      margin-top: 0em
      padding-left: 0em
      padding-right: 0em

    .moreLabel
      padding-top: 0em

    #list-container
      min-height: 4em

    .list-outline
      padding-top: 0
      padding-left: 1em
      margin: 0
      height: auto
      width: calc(100% - 2em)
      justify-content: space-between
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)

    .control-row
      padding: 0.5em
      padding-left: 2.8em
      padding-right: 2.8em

    .collection-list-box
      width: calc(100% - 3em)

    #left-selector
      margin-top: auto
      margin-bottom: auto
      display: block
      width: 0
      height: 0
      border-top: 1.5em solid transparent
      border-bottom: 1.5em solid transparent
      border-right: 15px solid var(--spendeapp-primary-color)
      cursor: pointer

    #right-selector
      display: block
      width: 0
      height: 0
      margin-top: auto
      margin-bottom: auto
      border-top: 1.5em solid transparent
      border-bottom: 1.5em solid transparent
      border-left: 15px solid var(--spendeapp-primary-color)
      cursor: pointer

    .collectionListItem
      display: none
      width: 100%
      margin: 0em
      cursor: auto

    .collectionListItem.active
      padding: 0.4em 1em 0.4em 1em
      display: flex
      width: unset

    // calc(100% - 2em - 4px)

    #dot-selector
      display: flex
      justify-content: center
      margin-bottom: 5px

      .selection-dot
        background: white
        width: 0.5em
        height: 0.5em
        border-radius: 1em
        border: 1px solid var(--spendeapp-primary-color)
        margin: 0.3em

      .selection-dot.active
        background: var(--spendeapp-primary-color)

    #explanation-text
      display: none

    #give-footer
      //padding-bottom: 1em
      padding-right: 1em

      #question-mark
        margin-left: 1em
.donation-box
  .donation-progress
    width: 100%
    height: 200px
    overflow: hidden
    position: relative
    border:1px solid #fff
    img
      position: absolute
      width: 100%
      height: 100%
      top:0
      left:0
      object-fit: cover
      z-index: 99
    div.progress-linear
      width: 200px
      transform: rotate(-90deg) translateX(-100%)
      transform-origin: top left
  .donation-text
    background-color: var(--spendeapp-primary-color)
    color: #fff
    font-size:14px
@media screen and (max-width: 737px)
  .donation-box
    .donation-progress
      height: 180px
      div.progress-linear
        width: 180px
    .donation-text
      font-size: 12px
@supports (-webkit-overflow-scrolling: touch)
  input[type="number"]
    font-size: 16px !important
    background: #eee

</style>
