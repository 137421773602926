<template>
  <div class="main">
    <v-list class="nav-list px-3 d-flex" >
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        :to="item.path" exact>
        {{ item.title }}
      </v-list-item>
    </v-list>
    <div class="content">
      <h1>Datenschutzerklärung kollekte.app / spende.app</h1>

      <p>Die nachfolgende Erklärung informiert Sie darüber, welche Art von Daten zu welchem Zweck auf dieser Website erhoben, verarbeitet, übermittelt bzw. genutzt werden.</p>

      <ol>
        <li class="decim">
          <h4>Name und Anschrift</h4>
          <ol>
            <li class="loalp">
              Verantwortlich im Sinne der DSGVO ist die Digital.Wolff, Plötz & Co GmbH mit Sitz in der Luckenwalder Str. 4-6, 10963 Berlin vertreten durch Dr. Martin C. Wolff, Henryk Plötz und Hannah Jo Wolff.
            </li>
            <li class="loalp">
              Kontakt
            </li>
            <ol>
              <li class="lorom">
                <a href="https://www.digitalwolff.de">www.digitalwolff.de</a>
              </li>
              <li class="lorom">
                <a href="mailto:datenschutz@digitalwolff.de">datenschutz@digitalwolff.de</a>
              </li>
            </ol>
            <li class="loalp">
              Datenschutzbeauftragter
            </li>
            <ol>
              <li class="lorom">
                Henryk Plötz
              </li>
              <li class="lorom">
                Paul Roßmann
              </li>
            </ol>
          </ol>
        </li>

        <li class="decim">
          <h4>Allgemeines zur Datenverarbeitung</h4>
          <ol>
            <li class="loalp">
              Die progressiven Web-Apps „kollekte.app“ und „spende.app“ werden von der Digital.Wolff, Plötz & Co GmbH zur Verfügung gestellt. Bei der Nutzung der Apps werden personenbezogene Daten verarbeitet.
            </li>
            <li class="loalp">
              Anlass der Datenerhebung
            </li>
            <ol>
              <li class="lorom">
                Die Apps ermöglichen es mittels Online-Zahlverfahren Kollekten (Dankopfer im Gottesdienst) und Spenden auf elektronischem Wege den jeweiligen Empfängerinstitutionen zukommen zu lassen. Zu diesem Zweck werden personenbezogene Daten verarbeitet.
              </li>
              <li class="lorom">
                {{ texts[currency].for_donation }}
              </li>
              <li class="lorom">
                Bei Spenden können auf expliziten Wunsch (opt-in) des Spenders oder der Spenderin personenbezogene Daten im Zusammenhang mit der Spende an die Empfängerinstitution übermittelt werden, etwa damit diese den Spender/der Spenderin weitere Informationen zukommen lassen kann. Dies liegt außerhalb des Zuständigkeitsbereichs der Digital.Wolff, Plötz & Co GmbH.
              </li>
            </ol>
            <li class="loalp">
              Umfang der Verarbeitung personenbezogener Daten
            </li>
            <ol>
              <li class="lorom">
                Es werden personenbezogene Daten erhoben um finanzrechtliche Auflagen und vertragliche Verpflichtungen von Zahlungsdienstleistern zu erfüllen, die diese wiederum zur Bedingung für die Durchführung der Zahlung machen,
              </li>
              <ol>
                <li class="upalp">
                  insbesondere im Rahmen der Dokumentationspflicht des Geldwäschegesetzes.
                </li>
              </ol>
              <li class="lorom">
                Nach Auswahl von Betrag, Zahlungsziel (Institution und Zweck) und Zahlverfahren ist die Eingabe personenbezogener Daten erforderlich, um die Zahlung auszulösen. Abhängig vom jeweiligen Zahlverfahren sind dies:
              </li>
              <ol>
                <li class="upalp">
                  Bei Kreditkarten:
                </li>
                <ol>
                  <li class="uprom">
                    Vorname, Name
                  </li>
                  <li class="uprom">
                    Stadt, Postleitzahl, Straße und Hausnummer
                  </li>
                  <li class="uprom">
                    E-Mail-Adresse
                  </li>
                  <li class="uprom">
                    Kreditkartendaten (Kartennummer, Gültigkeit, Prüfziffer)<br><i>Die Kreditkartennummer und Kartenprüfziffer wird in übereinstimmung mit den PCI-Richtlinien mittels der iframe-Technik direkt von unserem Zahlungsdienstleister und nicht von uns erhoben. Dies ist in der App nicht unmittelbar ersichtlich.</i>
                  </li>
                </ol>
                <template v-if="currency === 'CHF'">
                <li class="upalp">
                  Bei TWINT:
                </li>
                <ol>
                  <li class="uprom">
                    Vorname, Name
                  </li>
                  <li class="uprom">
                    Stadt, Postleitzahl, Straße und Hausnummer
                  </li>
                </ol>
              </template>
              <template v-if="currency !== 'CHF'">
                <li class="upalp">
                  Bei Lastschrift:
                </li>
                <ol>
                  <li class="uprom">
                    Vorname, Name
                  </li>
                  <li class="uprom">
                    IBAN
                  </li>
                </ol>
                <li class="upalp">
                  Bei Giropay:
                </li>
                <ol>
                  <li class="uprom">
                    Bankleitzahl bzw. BIC<br><i>Bei Giropay-Zahlungen erfolgt die weitere Zahlungsabwicklung über das giropay-System und insbesondere über das Online-Banking-System der Bank des Spenders/der Spenderin.</i>
                  </li>
                </ol>
              </template>
                <li clasS="upalp">
                  Bei Paypal:
                </li>
                <ol>
                  <li class="uprom">
                    Der Zahlungsprozess wird nach einer Weiterleitung im System von Paypal verarbeitet und abgeschlossen.
                  </li>
                </ol>
              </ol>
              <li class="lorom">
                Wenn die Ausstellung einer Zuwendungsbestätigung nach amtlichem Vordruck gewünscht ist, werden die dafür notwendigen Daten erhoben, an die Empfängerinstitution übermittelt, und von dieser für die Erstellung und das Zusenden der Zuwendungsbestätigung verwendet. Alternativ kann die Empfängerorganisation die Digital.Wolff, Plötz & Co GmbH mit der Erstellung und Zustellung der Zuwendungsbestätigung beauftragen. Die dafür nötigen Daten sind:
                <ol>
                  <li class="uprom">
                    Umstände der Spende (Betrag, Zweck, Datum, Organisation)
                  </li>
                  <li class="uprom">
                    Name, Anschrift
                  </li>
                  <li class="uprom">
                    E-Mail-Adresse
                  </li>
                </ol>
              </li>
              <li class="lorom">
                Wenn eine sich wiederholende Spende vereinbart wird, werden folgende Daten erhoben, um eine Möglichkeit zur Beendigung der Dauerspende zu schaffen:
                <ol>
                  <li class="uprom">
                    E-Mail-Adresse
                  </li>
                </ol>
              </li>
              <li class="lorom">
                Nach expliziter Zustimmung durch den Spender bzw. die Spenderin können folgenden Daten an die Empfängerorganisation übermittelt werden:
              </li>
              <ol>
                <li class="upalp">
                  Umstände der Spende (Betrag, Zweck, Datum, Organisation)
                </li>
                <li class="upalp">
                  Vorname, Name
                </li>
                <li class="upalp">
                  Adresse
                </li>
                <li class="upalp">
                  E-Mail-Adresse
                </li>
              </ol>
            </ol>
            <li class="loalp">
              Die Verarbeitung personenbezogener Daten erfolgt nur nach Einwilligung des Nutzers oder der Nutzerin.
            </li>
            <li class="loalp">
              Eine Ausnahme gilt in solchen Fällen, in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist.
            </li>
          </ol>
        </li>

        <li class="decim">
          <h4>Rechtsgrundlage für die Verarbeitung der personenbezogenen und Zahlungs-Daten sind</h4>
          <ol>
            <li class="loalp">
              Art. 6 Abs. 1 (b) DSGVO um ausgelöste Zahlungen vorzubereiten und abzuwickeln, oder um Zuwendungsbestätigungen zu erstellen und zuzustellen.
            </li>
            <li class="loalp">
              Art. 6 Abs. 1 (c) der DSGVO für die Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung.
            </li>
            <li class="loalp">
              Art. 6 Abs. 1 (f) DSGVO für die erforderliche Datenverarbeitung zur Wahrung eines berechtigten Interesses des Betreibers oder eines Dritten, insofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen.
            </li>
            <li class="loalp">
              Art. 6 Abs. 1 (a) DSGVO bei expliziter Zustimmung der betroffenen Person zur Weitergabe der Daten an die Empfängerorganisation.
            </li>
            <li class="loalp" v-if="currency === 'CHF'">bzw. das DSG der Schweiz, wenn die EU-DGSVO nicht zutrifft. </li>
          </ol>
        </li>

        <li class="decim">
          <h4>Dauer der Speicherung und Löschung</h4>
          <ol>
            <li class="loalp">
              {{ texts[currency].data_will_be_deleted }}
            </li>
            <li class="loalp">
              Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde.
            </li>
            <li class="loalp">
              Eine Löschung oder Sperrung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine Vertragserfüllung besteht.
            </li>
          </ol>
        </li>

        <li class="decim">
          <h4>Auftragsdatenverarbeitung</h4>
          <ol>
            <li class="loalp">
              {{ texts[currency].for_proccessing_payment }}
            </li>
            <li class="loalp">
              Wir verarbeiten alle Daten auf Servern in Rechenzentren der Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen. Hierzu besteht ein ADV-Vertrag, der Näheres festlegt.
            </li>
            <ol>
              <li class="lorom">
                Die Haupt-Rechenzentren befinden sich in Falkenstein, Deutschland und Nürnberg, Deutschland.
              </li>
              <li class="lorom">
                Ein Datenbackup liegt auf einem Server der Hetzner Online GmbH in Helsinki, Finnland.
              </li>
            </ol>
          </ol>
        </li>

        <li class="decim">
          <h4>Technische und organisatorische Maßnahmen</h4>
          <ol>
            <li class="loalp">
              Die Server und Backups sind verschlüsselt, so dass die Hetzner Online GmbH, oder andere Dritte, zu keinem Zeitpunkt Zugriff auf die Daten erhalten.
            </li>
            <li class="loalp">
              Alle übertragungen werden dem aktuellen Stand der Technik entsprechend verschlüsselt.
            </li>
            <li class="loalp">
              Informationen über Kollektenzahlungen werden grundsätzlich nur in aggregierter Form ausgegeben. Informationen über Spenden werden aufgeschlüsselt an die Empfängerorganisation übermittelt, wenn der Spender/die Spenderin die explizite Einwilligung zur Erstellung einer Zuwendungsbestätigung oder einer anderen Kontaktaufnahme erteilt hat, und ansonsten aggregiert.
            </li>
            <li class="loalp">
              Die Einsicht in Spenderdaten für Empfängerorganisationen erfolgt über ein TLS-geschütztes Portal. Es werden keine Listen mit personenbezogenen Daten über E-Mail oder andere unverschlüsselte übertragungswege ausgetauscht.
            </li>
            <li class="loalp">
              Dritte haben keinen Zugang zu den gespeicherten Daten.
            </li>
            <li class="loalp">
              Administrativer Zugang auf Betriebssystemebene ist mit Zwei-Faktor-Authentifizierung gesichert.
            </li>
          </ol>
        </li>

        <li class="decim">
          <h4>Auskunft, Berichtigung, Sperrung</h4>
          <ol>
            <li class="loalp">
              Die Digital.Wolff GmbH erteilt gemäß Art. 15 DSGVO auf Anfrage an o.g. Kontaktadresse Auskunft über personenbezogene Daten. Die Digital.Wolff GmbH berichtigt (gemäß Art. 16 DSGVO) oder sperrt (gemäß Art. 18 DSGVO) auf Anfrage an o.g. Kontaktadresse personenbezogene Daten.
            </li>
          </ol>
        </li>

        <li class="decim">
          <h4>Verwendung ohne personenbezogene Daten</h4>
          <ol>
            <li class="loalp">
              Zur Analyse der Verbreitung und Nutzung der App nutzen wir Analysewerkzeuge auf eigenen Servern auch schon vor Auslösung einer Zahlung.
            </li>
            <ol>
              <li class="lorom">
                Wir erfassen dabei nicht personenbeziehbare Daten der Systemumgebung (IPv4-Adresse auf 2 Byte trunkiert bzw. IPv6-Adresse auf 6 Byte trunkiert; Browser- und Betriebssystem-Version; Bildschirmauflösung).
              </li>
              <li class="lorom">
                Der Betrieb unterliegt denselben technischen Maßnahmen wie unter Punkt 6).
              </li>
            </ol>
          </ol>
        </li>

        <li class="decim">
          <h4>Änderungen</h4>
          <ol>
            <li class="loalp">
              Die Digital.Wolff GmbH behält sich vor, diese Datenschutzerklärung zu ändern, um sie an geänderte Rechtslagen, sowie an Änderungen des Dienstes oder der Datenverarbeitung anzupassen.
            </li>
            <ol>
              <li class="lorom">
                Dies gilt nur im Hinblick auf Erläuterungen zur Datenverarbeitung.
              </li>
              <li class="lorom">
                Sofern Einwilligungen der Nutzerinnen und Nutzer erforderlich sind oder Bestandteile der Datenschutzerklärung Regelungen des Vertragsverhältnisses mit den Nutzerinnen und Nutzern enthalten, erfolgen die Änderungen nur mit Zustimmung der Nutzerinnen und Nutzer.
              </li>
            </ol>
            <li class="loalp">
              Die Nutzerinnen und Nutzer werden angehalten, sich regelmäßig über den Inhalt der Datenschutzerklärung zu informieren.
            </li>
          </ol>
        </li>
      </ol>

      <p><i>Stand: Oktober 2024</i></p><br>
    </div>
  </div>

</template>

<script>
export default {
  name: 'DataPrivacy',
  data () {
    return {
      texts: {
        EUR: {
          for_donation: `Für Spenden über. idR. 300€ ist grundsätzlich die Ausstellung einer Zuwendungsbestätigung möglich. Zur Ausstellung, und ggf. Zustellung, der Zuwendungsbestätigung werden personenbezogene Daten verarbeitet.`,
          data_will_be_deleted: "Personenbezogene Daten werden gelöscht, sobald der Zweck der Speicherung entfällt oder der Nutzung widersprochen wird, insbesondere gemäß Art. 17 DSGVO.",
          for_proccessing_payment: 'Zur Abwicklung der Zahlungen agiert der Zahlungsdienstleister VR Payment GmbH, Saonestraße 3a, 60528 Frankfurt am Main in unserem Auftrag und verarbeitet die personenbezogenen und Zahlungs-Daten in seinen eigenen Rechenzentren. Hierzu besteht ein ADV-Vertrag, der Näheres festlegt.'
        },
        CHF: {
          for_donation: 'Für Spenden ist grundsätzlich die Ausstellung einer Verdankung möglich. Zur Ausstellung, und ggf. Zustellung, der Verdankung werden personenbezogene Daten verarbeitet.',
          data_will_be_deleted: "Personenbezogene Daten werden gelöscht, sobald der Zweck der Speicherung entfällt oder der Nutzung widersprochen wird, insbesondere gemäß Art. 17 DSGVO bzw. der Entsprechung im DSG.",
          for_proccessing_payment: "Zur Abwicklung der Zahlungen agiert der Zahlungsdienstleister wallee AG, Winterthur, Schweiz in unserem Auftrag und verarbeitet die personenbezogenen und Zahlungs-Daten in seinen eigenen Rechenzentren. Hierzu besteht ein ADV-Vertrag, der Näheres festlegt."
        },
      },
      menuItems: [
        { title: 'Datenschutzerklärung kollekte.app / spende.app Deutschland', path: '/datenschutz' },
        { title: 'Datenschutzerklärung kollekte.app / spende.app Schweiz', path: '/datenschutz/CHF' }
      ]
    }
  },

  computed: {
    currency () {
      return this.$route.params.currency || 'EUR'
    }
  }
}
</script>

<style scoped lang="stylus">
@import '../styles/main'
.nav-list
  width:fit-content
  margin-top:50px
  .v-list-item
    white-space: nowrap
.content
  overflow: auto
  padding-left: 3em
  padding-right: 3em
  padding-bottom: 3em
  top: 8em
  bottom: 0
  position: fixed
.decim
  list-style-type: decimal
.loalp
  list-style-type: lower-alpha
.upalp
  list-style-type: upper-alpha
.lorom
  list-style-type: lower-roman
.uprom
  list-style-type: upper-roman
</style>
